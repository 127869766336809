
import { SelectModel } from "@/core/models/SelectModel"
import { AccountCard, AccountCardType } from "@/core/models/AccountCard"
import { AccountCardDetail } from "@/core/models/AccountCardDetail"
import ApiService from "@/core/services/ApiService"
import { computed, defineComponent, onMounted, ref, toRefs, watch } from "vue"
import { useRouter } from "vue-router"
import { ElForm } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { TagType } from "@/core/models/Tag"
import { InternalRuleItem, Value } from "async-validator"

export default defineComponent({
  name: "account-card-component",
  components: {},
  props: ["add", "id", "closed", "activeBusiness", "activeBranch"],
  emits: ["closed", "getlist"],
  setup(props, { emit }) {
    const router = useRouter()
    const { add } = toRefs(props)

    const accountCardData = ref<AccountCard>({} as AccountCard)

    type FormInstance = InstanceType<typeof ElForm>
    const ruleFormRef = ref<FormInstance>()
    const isBranchActive = ref<boolean>(false)
    const isBusinessSelectLoading = ref(false)
    const isBranchSelectLoading = ref(false)
    const isTagSelectLoading = ref(false)
    const businessList = ref<SelectModel[]>([])
    const branchList = ref<SelectModel[]>([])
    const activeBusinessId = ref<string>()
    const activeBranchId = ref<string>()
    const tagList = ref<SelectModel[]>([])

    const accountCardTypeList = ref([
      {
        id: 1,
        name: "Kurumsal",
      },
      {
        id: 2,
        name: "Bireysel",
      },
    ])

    const tagTreeSelectProps = {
      label: "name",
      value: "id",
      children: "childs",
    }

    const changeEventType = arg => {
      if (arg == 1) {
        accountCardData.value.accountCardDetail = {} as AccountCardDetail
      } else accountCardData.value.accountCardDetail = undefined
    }

    const validateBusiness = (
      rule: InternalRuleItem,
      value: Value,
      callback: (error?: string | Error) => void
    ) => {
      if (!value && !isBranchActive.value) {
        callback(new Error("Lütfen İşletme Seçiniz!"))
      } else callback()
    }

    const validateBranch = (
      rule: InternalRuleItem,
      value: Value,
      callback: (error?: string | Error) => void
    ) => {
      if ((value == undefined || value == "") && isBranchActive.value) {
        callback(new Error("Lütfen Şube Seçiniz!"))
      } else callback()
    }

    const rules = ref({
      businessId: getRule(RuleTypes.SELECT, "İşletme", [
        {
          validator: validateBusiness,
          trigger: "change",
        },
      ]),
      branchId: getRule(RuleTypes.SELECT, "Şube", [
        {
          validator: validateBranch,
          trigger: "change",
        },
      ]),
      shortName: getRule(RuleTypes.TEXT100, "Kısa Ad"),
      accountCardType: getRule(RuleTypes.SELECT, "Cari Tipi"),
      tagId: getRule(RuleTypes.SELECT, "Cari Türü"),
      "accountCardDetail.title": getRule(RuleTypes.TEXT50, "Hesap"),
      "accountCardDetail.taxOffice": getRule(RuleTypes.TEXT50, "Vergi Dairesi"),
      "accountCardDetail.taxNumber": getRule(RuleTypes.TEXT50, "Vergi No"),
      "accountCardDetail.address": getRule(RuleTypes.TEXT100, "Adres"),
    })

    watch(
      () => props.id,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          accountCardData.value = {} as AccountCard
          getBusinessList()
          getAccountCard(newValue)
          activeBusinessId.value = props.activeBusiness as string
          activeBranchId.value = props.activeBranch as string
        }
      }
    )

    const showDrawer = computed(() => props.id != undefined || props.add == true)

    watch(
      () => props.add,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          accountCardData.value = {} as AccountCard
          if (props.activeBranch != undefined && props.activeBranch != "") {
            activeBusinessId.value = props.activeBusiness
            accountCardData.value.businessId = props.activeBusiness as string
            isBranchActive.value = true
            OnChangeBusiness(activeBusinessId.value)
            activeBranchId.value = props.activeBranch
            accountCardData.value.branchId = props.activeBranch as string
          } else {
            isBranchActive.value = false
            activeBranchId.value = undefined
            activeBusinessId.value = props.activeBusiness
            accountCardData.value.businessId = props.activeBusiness as string
            accountCardData.value.branchId = "" as string
          }
        }
      }
    )

    const getAccountCard = async id => {
      if (id != undefined && id != "") {
        const { data } = await ApiService.get("account-card/" + id)
        accountCardData.value = { ...data }
        accountCardData.value.branchId = data["brancId"] as string
        if (data.businessId == "00000000-0000-0000-0000-000000000000") {
          getBranch(accountCardData.value.branchId)
          activeBranchId.value = accountCardData.value.branchId
          isBranchActive.value = true
        } else {
          isBranchActive.value = false
          activeBranchId.value = undefined
          data["brancId"] = undefined
        }
      }
    }

    const getBusinessList = async () => {
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (businessList.value.length == 1) {
        activeBusinessId.value = businessList.value[0].id
        OnChangeBusiness(activeBusinessId.value)
      }
    }

    const getBranchList = async businessId => {
      isBranchSelectLoading.value = true
      const config = {
        params: {
          businessId: businessId,
        },
      }
      const { data } = await ApiService.query("branch", config)
      isBranchSelectLoading.value = false
      branchList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (branchList.value.length == 1) {
        activeBranchId.value = branchList.value[0].id
        OnChangeBranch(activeBranchId.value)
      }
    }

    const getBranch = async branchId => {
      const { data } = await ApiService.get("branch/" + branchId)
      accountCardData.value.businessId = data["businessId"]
      activeBusinessId.value = data["businessId"]
      getBranchList(data["businessId"])
    }

    const getTagList = async () => {
      var config = {
        params: {
          tagType: TagType.AccountCardType,
        },
      }
      isTagSelectLoading.value = false
      const { data } = await ApiService.query("tag/childs", config)
      tagList.value = data
      isTagSelectLoading.value = true
    }

    const addAccountCard = async () => {
      const data = { ...accountCardData.value }
      if (isBranchActive.value) delete data["businessId"]
      else delete data["branchId"]
      delete data["id"]
      if (accountCardData.value.accountCardType == AccountCardType.Individual)
        delete data["accountCardDetail"]

      await ApiService.post("account-card/add", data)
      if (!isBranchActive.value) emit("getlist", activeBusinessId.value, activeBranchId.value)
      else if (isBranchActive.value) emit("getlist", activeBusinessId.value, activeBranchId.value)
      router.push({ name: "account-card-list" })
    }
    const updateAccountCard = async () => {
      const data = { ...accountCardData.value, id: props.id }
      if (isBranchActive.value) delete data["businessId"]
      else delete data["branchId"]
      await ApiService.post("account-card/edit", data)
      if (!isBranchActive.value) emit("getlist", activeBusinessId.value, activeBranchId.value)
      else if (isBranchActive.value) emit("getlist", activeBusinessId.value, activeBranchId.value)
      router.push({ name: "account-card-list" })
    }

    const addOrUpdate = formEl => {
      if (!formEl) return
      formEl.validate(valid => {
        if (valid) {
          if (props.add) {
            addAccountCard()
          } else {
            updateAccountCard()
          }
        }
      })
    }

    const OnChangeBusiness = businessId => {
      accountCardData.value.branchId = "" as string
      activeBranchId.value = "" as string
      accountCardData.value.businessId = businessId

      if (isBranchActive.value) getBranchList(businessId)
    }

    const OnChangeBranch = branchId => {
      accountCardData.value.branchId = branchId
    }

    onMounted(async () => {
      accountCardData.value.id = props.id as string
      await getBusinessList()
      await getTagList()
      accountCardData.value = {} as AccountCard
      await getAccountCard(accountCardData.value.id)
    })

    return {
      showDrawer,
      newAccountCard: add,
      isBranchActive,
      handleClose: props.closed,
      accountCardData,
      addOrUpdate,
      ruleFormRef,
      businessList,
      activeBusinessId,
      activeBranchId,
      rules,
      isBusinessSelectLoading,
      isBranchSelectLoading,
      isTagSelectLoading,
      OnChangeBusiness,
      OnChangeBranch,
      branchList,
      tagList,
      accountCardTypeList,
      tagTreeSelectProps,
      changeEventType,
    }
  },
})
