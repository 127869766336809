export enum TagType {
  RevenueType = 1,
  ExpenseType = 2,
  AccountCardType = 3,
}

export interface Tag {
  id: string
  parentId?: string
  name?: string
  tagType: TagType
  tagStyle?: string
}
