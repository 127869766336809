
import ApiService from "@/core/services/ApiService"
import { defineComponent, onMounted, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import { useRoute, useRouter } from "vue-router"
import { DateFormatTypes, dateForm } from "@/core/helpers/dateformat"
import AccountCardComponent from "@/components/finance/definitions/account-card.vue"
import { AccountCard } from "@/core/models/AccountCard"
import { SelectModel } from "@/core/models/SelectModel"

import swalConfirm from "@/core/helpers/swal-confirm"
import { currencyForm } from "@/core/helpers/currencyformat"

export default defineComponent({
  name: "account-card-list",
  components: { AccountCardComponent },
  props: ["add"],
  setup(props) {
    const route = useRoute()
    const router = useRouter()

    const isTableDataLoading = ref(false)
    const isBusinessSelectLoading = ref(false)
    const isBranchSelectLoading = ref(false)
    const newAccountCard = ref(false)

    const businessList = ref<SelectModel[]>([])
    const branchList = ref<SelectModel[]>([])

    const accountCardList = ref<AccountCard[]>([] as AccountCard[])
    const activeBusinessId = ref<string>()
    const activeBranchId = ref<string>()
    const activeAccountCardId = ref<string | undefined>()

    watch(
      () => route.params.account_card_id,
      newValue => {
        activeAccountCardId.value = newValue as string
      }
    )

    watch(
      () => props.add,
      newValue => {
        newAccountCard.value = true == newValue
      }
    )

    const getBusinessList = async () => {
      isBusinessSelectLoading.value = true
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (businessList.value.length == 1) {
        activeBusinessId.value = businessList.value[0].id
        OnChangeBusiness(activeBusinessId.value)
      }
      isBusinessSelectLoading.value = false
    }

    const getBranchList = async businessId => {
      if (businessId != undefined) {
        isBranchSelectLoading.value = true
        const config = {
          params: {
            businessId: businessId,
          },
        }
        const { data } = await ApiService.query("branch", config)
        branchList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
        if (branchList.value.length == 1) activeBranchId.value = branchList.value[0].id
        isBranchSelectLoading.value = false
      }
    }

    const getAccountCardList = async (businessId, branchId) => {
      if (
        (businessId == undefined || businessId == "") &&
        (branchId == undefined || branchId == "")
      )
        return
      else if (branchId != undefined && branchId.length != 0) {
        isTableDataLoading.value = true
        activeBusinessId.value = businessId != undefined ? businessId : activeBusinessId.value
        await getBranchList(businessId)
        activeBranchId.value = branchId
        const { data } = await ApiService.get("account-card/branch/" + activeBranchId.value)
        accountCardList.value = data as AccountCard[]
        isTableDataLoading.value = false
      } else {
        isTableDataLoading.value = true
        activeBusinessId.value =
          activeBusinessId.value == undefined ? businessId : activeBusinessId.value
        activeBranchId.value = undefined
        const { data } = await ApiService.get("account-card/business/" + businessId)
        accountCardList.value = data as AccountCard[]
        isTableDataLoading.value = false
      }
    }

    const OnChangeBusiness = async businessId => {
      activeBranchId.value = undefined
      await getBranchList(businessId)
      getAccountCardList(businessId, activeBranchId.value)
    }

    const removeAccountCard = async accountCardId => {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) await ApiService.get("account-card/remove/" + accountCardId)
      await getAccountCardList(activeBusinessId.value, activeBranchId.value)
    }

    const handleAccountCardDrawerClose = () => {
      router.push({ name: "account-card-list" })
    }

    onMounted(async () => {
      activeAccountCardId.value = route.params.account_card_id as string
      newAccountCard.value = props.add
      await getBusinessList()
    })

    return {
      dateForm,
      DateFormatTypes,
      isTableDataLoading,
      accountCardList,
      businessList,
      branchList,
      activeBusinessId,
      activeBranchId,
      Edit,
      removeAccountCard,
      getAccountCardList,
      activeAccountCardId,
      newAccountCard,
      handleAccountCardDrawerClose,
      isBusinessSelectLoading,
      isBranchSelectLoading,
      OnChangeBusiness,
      currencyForm,
    }
  },
})
