import { AccountCardDetail } from "./AccountCardDetail"

export enum AccountCardType {
  Corporate = 1,
  Individual = 2,
}

export interface AccountCard {
  id?: string
  shortName: string
  tagId: string
  businessId?: string
  branchId?: string
  tagName: string
  accountCardType: AccountCardType
  accountCardTypeName: string
  accountCardDetail?: AccountCardDetail
  balance: number
}
